<template>
  <div id="user-list">
    <!-- list filters -->
    <v-card>
      <v-card-title class="d-block">
        <div class="d-flex align-center justify-space-between">
          <div>
            Search &amp; Filter
          </div>

          <v-btn
            color="primary"
            :loading="loading"
            @click="aside = 'exporter'"
          >
            <v-icon>{{ icons.mdiDownload }}</v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-title>

      <model-exporter-aside
        v-if="aside === 'exporter'"
        :active="true"
        :resource="getQuery()"
        :export-resource="exportVouchers"
        :enabled-plugins="false"
        @change="aside = false"
        @aside="(bol) => { if (!bol) { aside = ''}}"
      >
        <template slot="title">
          Export Vouchers
        </template>
      </model-exporter-aside>

      <v-row class="px-2 ma-0">
        <!-- search filter -->
        <v-col
          cols="12"
          sm="3"
          class="d-flex align-center"
        >
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>

        <!-- status filter -->
        <v-col
          cols="12"
          sm="9"
          class="d-flex align-center"
        >
          <v-autocomplete
            v-model="merchantFilter"
            placeholder="Merchant"
            :items="merchantOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="voucherListTable"
        :options.sync="options"
        :server-items-length="totalListTable"
        :loading="loading"
      >
        <!-- Image -->
        <template #[`item.media`]="{item}">
          <main-media
            :media="item.sku.product.media"
            :sku-id="item.sku_id"
          >
          </main-media>
        </template>

        <!-- ID -->
        <template #[`item.voucher_number`]="{item}">
          <div
            style="min-width: 120px;"
          >
            {{ item.code }}
          </div>
        </template>

        <!-- Name -->
        <template #[`item.voucher`]="{item}">
          <div class="d-flex align-center max-300">
            <router-link
              :to="{ name: 'product-view', query: { tab: 'VOUCHERS' }, params: { id : item.sku.product_id } }"
              class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
            >
              <small>{{ t(item.sku.product.title) }}</small>
            </router-link>
          </div>
        </template>

        <!-- Merchant -->
        <template #[`item.merchant`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="'primary'"
              :class="'v-avatar-light-bg primary--text'"
              size="32"
            >
              <span
                class="font-weight-medium"
              >{{ avatarText(item.sku.product.merchant.name.en) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <span
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ t(item.sku.product.merchant.name) }}
              </span>
            </div>
          </div>
        </template>

        <!-- updated at -->
        <template #[`item.updated_at`]="{item}">
          <div
            style="min-width: 100px;"
          >
            <span>{{ item.updated_at | date }}</span>
          </div>
        </template>

        <!-- actions -->
        <!-- <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="item.status === 'draft'"
                link
                @click="confirmTrashVoucher(item).then(loadVouchers)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Trash</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template> -->
      </v-data-table>
    </v-card>

    <voucher-update-aside
      v-if="asideVoucher"
      :active="true"
      :voucher-id="asideVoucher.id"
      :product="asideVoucher.sku.product"
      @changed="voucher => { closeAside(false); loadVouchers() }"
      @aside="bol => { closeAside(bol); !bol && loadVouchers() }"
    />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiDownload,
} from '@mdi/js'

// sidebar
import { avatarText, date, localPrice } from '@core/utils/filter'

import { MainMedia, ModelExporterAside } from '@/components'
import merchantStore from '@/modules/merchant/store'
import { t } from '@/plugins/i18n'
import { computed, ref } from '@vue/composition-api'
import useVoucher from '../../composables/useVoucher'
import useProduct from '../useProduct'
import useVoucherList from './useVoucherList'

export default {
  components: {
    MainMedia, ModelExporterAside,
  },
  filters: { date, localPrice },
  setup() {
    const {
      voucherListTable,
      tableColumns,
      searchQuery,
      merchantFilter,
      totalListTable,
      loading,
      options,
      totalLocal,
      selectedRows,

      loadVouchers,
      getQuery,
    } = useVoucherList()
    const { exportVouchers } = useVoucher()

    const merchantOptions = computed(() => merchantStore.state.all.map(merchant => ({ title: `${t(merchant.name)} (${merchant.code})`, value: merchant.id })))

    const { unpublishVoucher, confirmTrashVoucher } = useProduct()
    const aside = ref('')
    const asideVoucher = ref(null)
    const closeAside = bool => {
      if (!bool) {
        asideVoucher.value = null
      }
    }

    return {
      voucherListTable,
      totalListTable,
      tableColumns,
      searchQuery,
      merchantFilter,
      merchantOptions,
      loading,
      options,
      totalLocal,
      selectedRows,
      avatarText,
      loadVouchers,
      getQuery,
      exportVouchers,
      unpublishVoucher,
      confirmTrashVoucher,

      t,

      closeAside,
      asideVoucher,
      aside,

      // icons
      icons: {
        mdiDownload,
      },
    }
  },
}
</script>

  <style lang="scss">
  @import '@core/preset/preset/apps/user.scss';

  .max-300 {
    max-width: 300px;
  }
  </style>
