import { fetchVouchers } from '@/api/product'
import { useTableOptions } from '@/composables'
import { computed, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default function useVoucherList() {
  const voucherListTable = ref([])

  const tableColumns = [
    { text: 'IMAGE', value: 'media', sortable: false },
    { text: 'CODE', value: 'voucher_number' },
    { text: 'VOUCHER', value: 'voucher', sortable: false },
    { text: 'MERCHANT', value: 'merchant', sortable: false },
    { text: 'UPDATED AT', value: 'updated_at' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
    },
  )
  const searchQuery = ref(cached[1] || '')
  const merchantFilter = ref(cached[2] || null)
  const totalListTable = ref(0)
  const loading = ref(false)
  const totalLocal = ref([])
  const selectedRows = ref([])

  // must follow cached[0...4] variable order above.
  const voucherOptions = computed(() =>
    voucherListTable.value.map(voucher => ({
      title: voucher.name.en,
      value: voucher.id,
    })),
  )

  const getQuery = () =>
    useTableOptions(options.value, {
      search: searchQuery.value,
      merchantId: merchantFilter.value,
    })

  // fetch data
  const loadVouchers = () =>
    fetchVouchers(getQuery())
      .then(({ data }) => {
        const { records, pagination } = data.data

        voucherListTable.value = records
        totalListTable.value = pagination.total
        totalLocal.value = null

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const cachable = [options, searchQuery, merchantFilter]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(
    cachable,
    debounce(() => {
      const newPage = options.value.page

      // reset page if filter others (i.e. no page change)
      const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

      if (newPage >= 2 && hasDiff) {
        options.value.page = 1
      }

      loading.value = true
      selectedRows.value = []
      loadVouchers()
      cache()
    }, 300),
  )

  return {
    voucherOptions,
    voucherListTable,
    tableColumns,
    searchQuery,
    merchantFilter,
    totalListTable,
    loading,
    options,
    totalLocal,
    selectedRows,
    loadVouchers,
    getQuery,
  }
}
